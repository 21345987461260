<template>
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="row align-items-center mb-0 pb-5">
                        <div class="col-6 col-sm-6 col-md-6 text-left">
                            <div class="logo-wrapper"><img src="@/assets/img/bau-logo-for-light.svg"/></div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 text-right">
                            <h4>{{ $t('graduate_application_form').toUpper() }}</h4>
                            <b-button v-if="stateData.personalInfo && Object.keys(stateData.personalInfo).length > 0" @click="logout" variant="danger" class="float-right"
                                      size="sm">{{ $t('logout').toUpper() }}
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="stateData.tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab :disabled="!(tabIndex == 0)">
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('personal_information') }}</template>
                            <ValidationObserver ref="personalInfoForm">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="nationalId" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('national_id')">
                                                <b-form-input
                                                    :disabled="updateSwitch"
                                                    :placeholder="$t('national_id_placeholder')"
                                                    v-mask="'###########'"
                                                    :state="errors[0] ? false : null"
                                                    v-model="personalInfoForm.nationalId"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    v-mask="letterAndSpaceMask"
                                                    :disabled="updateSwitch"
                                                    :placeholder="$t('name_placeholder')"
                                                    :state="errors[0] ? false : null"
                                                    v-model="personalInfoForm.name"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="surname" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-mask="letterAndSpaceMask"
                                                    :disabled="updateSwitch"
                                                    :placeholder="$t('surname_placeholder')"
                                                    :state="errors[0] ? false : null"
                                                    v-model="personalInfoForm.surname"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="gender" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gender')">
                                                <gender-selectbox
                                                    :disabled="updateSwitch"
                                                    v-model="personalInfoForm.gender"
                                                    :validateError="errors[0]"></gender-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date_of_birth')">
                                                <v-date-picker
                                                    v-model="personalInfoForm.birthdate"
                                                    locale="tr"
                                                    is-expanded
                                                    :masks="{ input: 'DD-MM-YYYY'}"
                                                    :popover="{ 'visibility': updateSwitch == false ? 'click' : '' }">
                                                    <template
                                                        v-slot="{ inputValue, inputEvents }">
                                                        <b-input-group>
                                                            <b-form-input
                                                                :readonly="true"
                                                                :value="inputValue"
                                                                v-on="inputEvents"
                                                                :state="errors[0] ? false : null"
                                                            ></b-form-input>
                                                            <b-input-group-append>
                                                                <b-button
                                                                    variant="outline-secondary"
                                                                    class="btn-40"
                                                                    disabled
                                                                >
                                                                    <i class="ri-calendar-line"></i
                                                                    ></b-button>
                                                            </b-input-group-append>
                                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                                        </b-input-group>
                                                    </template>
                                                </v-date-picker>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="nationalityCode" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox
                                                    valueType="code"
                                                    :public="true"
                                                    :validateError="errors[0]"
                                                    v-model="personalInfoForm.nationalityCode">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="militaryStatus" :rules="personalInfoForm.gender == 'Kadın' ? '' : 'required'" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('military_status')">
                                                <parameter-selectbox v-model="personalInfoForm.militaryStatus"
                                                                     :disabled="personalInfoForm.gender == 'Kadın' ? true : false"
                                                                     code="military_statuses"
                                                                     :public="true"
                                                                     :validate-error="errors[0]"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('email')">
                                                <b-form-input
                                                    :placeholder="$t('email_placeholder')"
                                                    :state="errors[0] ? false : null"
                                                    v-model="personalInfoForm.email"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="phone" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mobile_number')">
                                                <b-form-input
                                                    :placeholder="$t('mobile_placeholder')"
                                                    v-mask="'(5##) ###-####'"
                                                    :state="errors[0] ? false : null"
                                                    v-model="personalInfoForm.phone"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="applicationType" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('application_type')">
                                                <parameter-selectbox v-model="personalInfoForm.applicationType"
                                                                     code="graduate_application_types"
                                                                     :public="true"
                                                                     :validate-error="errors[0]"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </b-tab>
                        <b-tab :disabled="!(tabIndex == 1)">
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('program_select') }}</template>
                            <div class="row" v-if="tabIndex == 1">
                                <div class="col-12 col-sm-6 col-md-6">
                                    <b-form-group :label="$t('program')">
                                        <program-groups-selectbox
                                            v-model="programFilter.department">
                                        </program-groups-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-3 col-md-3">
                                    <b-form-group :label="$t('program_level')">
                                        <program-level-selectbox
                                            v-model="programFilter.level">
                                        </program-level-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-3 col-md-3">
                                    <b-form-group :label="$t('with_without_thesis')">
                                        <thesis-with-true-false-selectbox
                                            v-model="programFilter.withThesis">
                                        </thesis-with-true-false-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <b-form-group :label="$t('language')">
                                        <language-selectbox
                                            v-model="programFilter.language">
                                        </language-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <b-form-group :label="$t('campus')">
                                        <campus-selectbox
                                            :public="true"
                                            v-model="programFilter.campusId">
                                        </campus-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4">
                                    <b-form-group :label="$t('program_days')">
                                        <program-days-selectbox
                                            v-model="programFilter.programDays">
                                        </program-days-selectbox>
                                    </b-form-group>
                                </div>
                                <div class="col-12 mt-4">
                                    <h6>{{ $t('programs_to_choose') }}</h6>
                                </div>
                                <div class="col-12">
                                    <b-table
                                        :empty-text="this.$t('program_information')"
                                        bordered
                                        hover
                                        striped
                                        responsive
                                        :items="availablePrograms"
                                        :fields="availableProgramsFields"
                                        show-empty
                                        class="mb-5 table-dropdown no-scrollbar border rounded">
                                        <template #cell(buttons)="row">
                                            <div>
                                                <b-button @click="sendToSelecteds(row)" variant="primary" size="sm">{{ $t('select_btn') }}</b-button>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="col-12 mt-2">
                                    <hr/>
                                </div>
                                <div class="col-12 mt-4">
                                    <h6>{{ $t('programs_selected') }}</h6>
                                </div>
                                <div class="col-12">
                                    <b-table
                                        :empty-text="this.$t('there_are_no_records')"
                                        bordered
                                        hover
                                        striped
                                        responsive
                                        :items="selectedPrograms"
                                        :fields="selectedProgramsFields"
                                        show-empty
                                        class="mb-5 table-dropdown no-scrollbar border rounded">
                                        <template #cell(buttons)="row">
                                            <div>
                                                <b-button @click="sendToAvailables(row)" variant="danger" size="sm" :title="$t('delete')"><i
                                                    class="ri-delete-bin-line"></i></b-button>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab :disabled="!(tabIndex == 2)">
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('job_and_contact_info') }}</template>
                            <ValidationObserver ref="jobContactForm">
                                <div class="row" v-if="tabIndex == 2">
                                    <div class="col-12">
                                        <h6>{{ $t('job_info') }}</h6>
                                    </div>
                                    <div class="col-12">
                                        <hr/>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="sector" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job_sector')">
                                                <parameter-selectbox v-model="jobContactForm.sector"
                                                                     code="graduate_application_sectors"
                                                                     :public="true"
                                                                     :validate-error="errors[0]"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="corporation" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job_corporation')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.corporation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="job" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.job"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="jobDepartment" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job_department')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.jobDepartment"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="title" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('title1')">
                                                <parameter-selectbox v-model="jobContactForm.title"
                                                                     code="graduate_application_work_titles"
                                                                     :public="true"
                                                                     :validate-error="errors[0]"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="experience" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('job_experience')">
                                                <parameter-selectbox v-model="jobContactForm.experience"
                                                                     code="graduate_application_work_experiences"
                                                                     :public="true"
                                                                     :description="$t('job_experience_desc')"
                                                                     :validate-error="errors[0]"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <h6>{{ $t('contact_information') }}</h6>
                                    </div>
                                    <div class="col-12">
                                        <hr/>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <h6>{{ $t('home_address') }}</h6>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="neighborhood" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('neighborhood')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.neighborhood"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="street" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('street')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.street"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="streetNo" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('street_and_no')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.streetNo"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="city" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="jobContactForm.city">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="district" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('district')">
                                                <district-outline-selectbox
                                                    :city_id="jobContactForm.city"
                                                    :validateError="errors[0]"
                                                    v-model="jobContactForm.district">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <div class="row">
                                            <div class="col-4 col-sm-4 col-md-4">
                                                <ValidationProvider name="homeTelCode" rules="" v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('code')">
                                                        <b-form-input
                                                            v-mask="'###'"
                                                            :state="errors[0] ? false : null"
                                                            v-model="jobContactForm.homeTelCode"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-8 col-sm-8 col-md-8">
                                                <ValidationProvider name="homeTel" rules="" v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('home_tel')">
                                                        <b-form-input
                                                            v-mask="'#######'"
                                                            :state="errors[0] ? false : null"
                                                            v-model="jobContactForm.homeTel"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <h6>{{ $t('job_address') }}</h6>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="neighborhoodJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('neighborhood')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.neighborhoodJob"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="streetJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('street')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.streetJob"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="streetNoJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('street_and_no')">
                                                <b-form-input
                                                    :state="errors[0] ? false : null"
                                                    v-model="jobContactForm.streetNoJob"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="cityJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="jobContactForm.cityJob">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <ValidationProvider name="districtJob" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('district')">
                                                <district-outline-selectbox
                                                    :city_id="jobContactForm.cityJob"
                                                    :validateError="errors[0]"
                                                    v-model="jobContactForm.districtJob">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4">
                                        <div class="row">
                                            <div class="col-4 col-sm-4 col-md-4">
                                                <ValidationProvider name="jobTelCode" rules="" v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('code')">
                                                        <b-form-input
                                                            v-mask="'###'"
                                                            :state="errors[0] ? false : null"
                                                            v-model="jobContactForm.jobTelCode"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-8 col-sm-8 col-md-8">
                                                <ValidationProvider name="jobTel" rules="" v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('job_tel')">
                                                        <b-form-input
                                                            v-mask="'#######'"
                                                            :state="errors[0] ? false : null"
                                                            v-model="jobContactForm.jobTel"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </b-tab>
                        <b-tab :disabled="!(tabIndex == 3)">
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('education_and_exam_info') }}</template>
                                <div class="row" v-if="tabIndex == 3">
                                    <div class="col-12">
                                        <h6>{{ $t('education_info') }}</h6>
                                    </div>
                                    <div class="col-12">
                                        <hr/>
                                    </div>
                                    <b-col cols="12" class="mb-3 d-flex justify-content-center">
                                        <b-button variant="primary" @click="searchByYoksis">{{ $t('search_by_yoksis') }}</b-button>
                                    </b-col>
                                    <div class="col-12">
                                        <b-table
                                            :empty-text="this.$t('there_are_no_records')"
                                            bordered
                                            hover
                                            striped
                                            responsive
                                            :items="selectedEducations"
                                            :fields="selectedEducationsFields"
                                            show-empty
                                            class="mb-5 table-dropdown no-scrollbar border rounded">
                                            <template #cell(button)="row">
                                                <div>
                                                    <b-button @click="deleteFromSelectedEducations(row)" variant="danger" size="sm" :title="$t('delete')"><i
                                                        class="ri-delete-bin-line"></i></b-button>
                                                </div>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <h6>{{ $t('exam_info') }}</h6>
                                    </div>
                                    <div class="col-12">
                                        <hr/>
                                    </div>
                                    <b-col cols="12" class="mb-3 d-flex justify-content-center">
                                        <b-button variant="primary" @click="openOsymModal">{{ $t('search_by_osym') }}</b-button>
                                    </b-col>
                                    <div class="col-12">
                                        <b-table
                                            :empty-text="this.$t('there_are_no_records')"
                                            bordered
                                            hover
                                            striped
                                            responsive
                                            :items="selectedExams"
                                            :fields="selectedExamsFields"
                                            show-empty
                                            class="mb-5 table-dropdown no-scrollbar border rounded">
                                            <template #cell(button)="row">
                                                <div>
                                                    <b-button @click="deleteFromSelectedExams(row)" variant="danger" size="sm" :title="$t('delete')"><i
                                                        class="ri-delete-bin-line"></i></b-button>
                                                </div>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            <ValidationObserver ref="bauExamForm">
                                <div v-if="bauExamShow" class="row">
                                    <div class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider name="bauExam" :rules="bauExamShow ? 'required' : ''" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('bau_language_exam_question')">
                                                <yes-no-selectbox
                                                    :description="$t('bau_language_exam_info')"
                                                    v-model="bauExamForm.bauExam"
                                                    :validate-error="errors[0]"></yes-no-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </b-tab>
                        <b-tab :disabled="!(tabIndex == 4)">
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('documents') }}</template>
                            <ValidationObserver ref="documentsForm">
                                <div class="row" v-if="tabIndex == 4">
                                    <div class="col-12 mb-2">
                                        <p>{{ $t('ph_documents_info') }}</p>
                                        <p>{{ $t('graduate_application_yok_statement') }}</p>
                                    </div>
                                    <div class="col-12" v-for="(file,index) in requiredDocuments" v-bind:key="index">
                                        <b-row no-gutters class="file-upload-box mb-1">
                                            <b-col sm="12" md="6" lg="5">
                                                <div class="filename d-flex" :class="{'active': index == 'other_documents' ? otherDocuments.length > 0 ? true : false : file.status != 'waiting' ? true : false}">
                                                    <span class="flex-grow-1">{{
                                                            ['thesis_project_documents', 'graduate_transcript', 'post_graduate_diploma'].includes(index) ? '(*) ' + getLocaleText(file,'name') : getLocaleText(file,'name')
                                                        }}</span>
                                                </div>
                                            </b-col>
                                            <b-col sm="12" md="6" lg="7">
                                                <b-input-group>
                                                    <b-form-file
                                                        :ref="index"
                                                        v-model="files[index]"
                                                        :placeholder="file.file_name != null ? file.file_name : $t('select_file')+'...'"
                                                    >
                                                        <b-form-file id="file-small"></b-form-file>
                                                    </b-form-file>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40 download"
                                                                  :v-b-popover.hover.right="$t('download')"
                                                                  v-if="file.status != 'waiting' ? true : false"
                                                                  @click="downloadFile(index, file.file_name, 0)">
                                                            <i class="ri-download-2-line"></i>
                                                        </b-button>
                                                        <b-button variant="danger" class="btn-40"
                                                                  :v-b-popover.hover.right="$t('delete')"
                                                                  v-if="file.status != 'waiting' ? true : false"
                                                                  @click="deleteFile(index, 0)">
                                                            <i class="ri-delete-bin-line"></i>
                                                        </b-button>
                                                        <b-button variant="outline-secondary" class="btn-40"
                                                                  :v-b-popover.hover.right="$t('upload')"
                                                                  v-if="['waiting','denied'].includes(file.status)"
                                                                  @click="upload(index)">
                                                            <i class="ri-add-fill"></i>
                                                        </b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="col-12" v-if="Object.keys(requiredDocuments).length > 8">
                                        {{ $t('ph_programs_info') }}
                                    </div>
                                    <div class="col-12" v-if="otherDocuments.length > 0">
                                        <div class="border pt-3 mb-5 rounded">
                                            <div class="col-12" v-for="(file,index) in otherDocuments" v-bind:key="index">
                                                <b-row no-gutters class="file-upload-box mb-1">
                                                    <b-col sm="12" md="6" lg="5">
                                                        <div class="filename d-flex" :class="{'active': file.status != 'waiting' ? true : false}">
                                                    <span class="flex-grow-1">{{$t('other_documents')}}</span>
                                                        </div>
                                                    </b-col>
                                                    <b-col sm="12" md="6" lg="7">
                                                        <b-input-group>
                                                            <b-form-file
                                                                :ref="index"
                                                                v-model="files[index]"
                                                                :placeholder="file.file_name!=null?file.file_name:$t('select_file')+'...'"
                                                                :disabled="true"
                                                            >
                                                                <b-form-file id="file-small"></b-form-file>
                                                            </b-form-file>
                                                            <b-input-group-append>
                                                                <b-button variant="outline-secondary" class="btn-40 download"
                                                                          :v-b-popover.hover.right="$t('download')"
                                                                          v-if="file.status != 'waiting' ? true : false"
                                                                          @click="downloadFile(index, file.file_name, file.id)">
                                                                    <i class="ri-download-2-line"></i>
                                                                </b-button>
                                                                <b-button variant="danger" class="btn-40"
                                                                          :v-b-popover.hover.right="$t('delete')"
                                                                          v-if="file.status != 'waiting' ? true : false"
                                                                          @click="deleteFile(index, file.id)">
                                                                    <i class="ri-delete-bin-line"></i>
                                                                </b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </b-tab>
                        <b-tab :disabled="!(tabIndex == 5)">
                            <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('preview') }}</template>
                            <application-form-preview
                                v-if="tabIndex == 5"
                                :personalInfo="stateData"
                                @goToUpdate="goToUpdate"></application-form-preview>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
            <div class="row mb2">
                <div class="d-flex justify-content-between">
                    <b-button @click="sendSms" v-if="tabIndex == 0" type="button" variant="primary">{{ $t('next').toUpper() }}</b-button>
                    <b-button @click="savePrograms" v-if="tabIndex == 1" type="button" variant="primary">{{ $t('next').toUpper() }}</b-button>
                    <b-button @click="saveJobContact" v-if="tabIndex == 2" type="button" variant="primary">{{ $t('next').toUpper() }}</b-button>
                    <b-button @click="saveEducationExam" v-if="tabIndex == 3" type="button" variant="primary">{{ $t('next').toUpper() }}</b-button>
                    <b-button @click="saveDocuments" v-if="tabIndex == 4" type="button" variant="primary">{{ $t('next').toUpper() }}</b-button>
                    <b-button @click="savePreview" v-if="tabIndex == 5" type="button" variant="primary">{{ $t('save').toUpper() }}</b-button>
                </div>
            </div>
        </div>
        <CommonModal ref="kvkkModal" size="md" :onHideOnlyX="true" :closeBtn="false">
            <template v-slot:CommonModalTitle>
                <div>KVKK</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="row">
                        <b-button variant="link" @click="showKvkk1Modal">{{ $t('statement_3').toUpper() }}</b-button>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="d-flex justify-content-between">
                                <b-button variant="primary" @click="acceptToContinue">{{ $t('read_and_accept') }}</b-button>
                                <b-button variant="light" @click="closeKvkkModal">{{ $t('cancel') }}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="kvkk1Modal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('statement_3').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="row">
                        <div v-html="$t('kvkk_content')" class="col-12">
                        </div>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="kvkk2Modal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('statement_4').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="row">
                        <div class="col-12">
                            {{ $t('kvkk_content_pilotaj') }}
                        </div>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="graduateInfoModal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('info_text').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="row">
                        <div v-html="$t('graduate_app_info')" class="col-12"></div>
                        <div class="col-12 mt-4">
                            <b-button variant="primary" @click="continueInfoModal">{{ $t('continue') }}</b-button>
                        </div>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="smsVerifyModal" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('verification') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="form-group">{{ $t('write_verify_code_mail') }}</div>
                    <div>
                        <b-alert :variant="alertVariant" :show="alertShow"><span
                            v-if="alertVariant == 'success'">{{ $t('verify_code_again_not') }}</span> <span
                            v-if="alertVariant == 'danger'">{{ $t('send_again_not') }}</span></b-alert>
                        <ValidationObserver ref="smsVerifyActionForm">
                            <div class="mb-3">
                                <ValidationProvider name="smsVerifyCode" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="col-form-label">{{ $t('verification_code') }}</label>
                                            <div class="text-muted"><i
                                                class="ri-time-line top-plus-2 mr-1"></i>{{ timerCount }} sn
                                            </div>
                                        </div>
                                        <b-form-input
                                            :state="errors[0] ? false : null"
                                            v-model="smsVerifyActionForm.verifyCode"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="d-flex justify-content-between">
                                <b-button type="button" variant="primary" @click="approvalSmsVerify">{{ $t('verify') }}</b-button>
                                <b-button type="button" variant="outline-secondary" @click="EnterCodeAgainFunc()"
                                          v-show="smsSendAgainButtonStatus">{{ $t('send_again_btn') }}
                                </b-button>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="smsVerifyUpdateModal" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('verification') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="form-group">{{ $t('write_verify_code') }}</div>
                    <div>
                        <b-alert :variant="alertVariantUpdate" :show="alertShowUpdate"><span
                            v-if="alertVariantUpdate == 'success'">{{ $t('verify_code_again_not') }}</span> <span
                            v-if="alertVariantUpdate == 'danger'">{{ $t('send_again_not') }}</span></b-alert>
                        <ValidationObserver ref="smsVerifyActionUpdateForm">
                            <div class="mb-3">
                                <ValidationProvider name="smsVerifyCodeUpdate" rules="required" v-slot="{valid, errors}">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="col-form-label">{{ $t('verification_code') }}</label>
                                            <div class="text-muted"><i
                                                class="ri-time-line top-plus-2 mr-1"></i>{{ timerCountUpdate }} sn
                                            </div>
                                        </div>
                                        <b-form-input
                                            :state="errors[0] ? false : null"
                                            v-model="smsVerifyActionUpdateForm.verifyCode"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="d-flex justify-content-between">
                                <b-button type="button" variant="primary" @click="approvalSmsVerifyUpdate">{{ $t('verify') }}</b-button>
                                <b-button type="button" variant="outline-secondary" @click="EnterCodeAgainFuncUpdate()"
                                          v-show="smsSendAgainButtonStatusUpdate">{{ $t('send_again_btn') }}
                                </b-button>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="yoksisSearchModal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('yoksis_results').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <b-row v-if="yoksisSearchSwitch">
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </b-col>
                        <b-col cols="12" class="text-center mt-2">
                            {{ $t('yoksis_searching') }}
                        </b-col>
                    </b-row>
                    <b-row v-if="!yoksisSearchSwitch">
                        <b-col cols="12">
                            <b-table :empty-filtered-text="$t('no_result')"
                                     :empty-text="$t('no_result')"
                                     bordered
                                     striped
                                     responsive
                                     :items="yoksisData"
                                     :fields="selectedEducationsFields"
                                     class="mb-0 table-dropdown no-scrollbar border rounded"
                                     ref="yoksisTable">
                                <template #cell(button)="row">
                                    <div>
                                        <b-button v-if="!row.item.added" @click="addToEducationsFromList(row)" variant="primary" size="sm" :title="$t('add')">
                                            <i class="ri-add-line"></i>
                                        </b-button>
                                        <b-button v-if="row.item.added" variant="success" size="sm" :title="$t('added')">
                                            <i class="ri-check-line"></i>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="12" class="mt-2">
                            <b-button @click="yoksisManuelSwitch = !yoksisManuelSwitch" variant="primary">{{ $t('add_info_manuel') }}</b-button>
                        </b-col>
                        <b-col v-show="yoksisManuelSwitch" cols="12">
                            <div class="border rounded mt-2 p-4">
                            <ValidationObserver ref="educationExamForm">
                                <b-row>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="graduationDegree" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_degree')">
                                            <parameter-selectbox v-model="educationExamForm.graduationDegree"
                                                                 code="graduate_application_graduation_degrees"
                                                                 :public="true"
                                                                 :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="university" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_university')">
                                            <university-selectbox
                                                :public="true"
                                                v-model="educationExamForm.university"
                                                :validate-error="errors[0]"></university-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="faculty" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <b-form-input
                                                :state="errors[0] ? false : null"
                                                v-model="educationExamForm.faculty"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('department')">
                                            <b-form-input
                                                :state="errors[0] ? false : null"
                                                v-model="educationExamForm.department"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="year" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_year')">
                                            <years-selectbox
                                                :interval="40"
                                                v-model="educationExamForm.year"
                                                :validate-error="errors[0]"></years-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <ValidationProvider name="average" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('graduation_average')">
                                            <b-form-input
                                                v-mask="educationExamForm.averageSystem == 'four_system' ? floatMaskFour : floatMask"
                                                min="0.00"
                                                :max="educationExamForm.averageSystem == 'four_system' ? 4 : 100"
                                                v-check-min-max-value
                                                :state="errors[0] ? false : null"
                                                v-model="educationExamForm.average"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-form-group v-slot="{ ariaDescribedby }">
                                        <label class="mt-5"> </label>
                                        <ValidationProvider name="averageSystem" rules="required" v-slot="{valid, errors}">
                                            <b-form-radio-group v-model="educationExamForm.averageSystem" :aria-describedby="ariaDescribedby">
                                                <b-form-radio value="four_system">{{ $t('average_system_4') }}</b-form-radio>
                                                <b-form-radio value="hundred_system">{{ $t('average_system_100') }}</b-form-radio>
                                            </b-form-radio-group>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" class="d-flex justify-content-center mt-4">
                                    <b-button @click="addToEducations" type="button" variant="primary">{{ $t('add').toUpper() }}</b-button>
                                </b-col>
                                </b-row>
                            </ValidationObserver>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="osymSearchModal" size="xl">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('osym_exam_results').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <b-row v-if="[1,3].includes(osymSearchSwitch)">
                        <b-col cols="12" md="4" class="mb-2">
                            <b-form-group :label="$t('exam_result_year')">
                                <years-selectbox v-model="osymYear" @input="searchByOsym" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="osymSearchSwitch == 2">
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-spinner variant="primary" label="Spinning"></b-spinner>
                        </b-col>
                        <b-col cols="12" class="text-center mt-2">
                            {{ $t('osym_searching') }}
                        </b-col>
                    </b-row>
                    <b-row v-if="osymSearchSwitch == 3">
                        <b-col cols="12">
                            <b-table :empty-filtered-text="$t('no_result')"
                                     :empty-text="$t('no_result')"
                                     bordered
                                     striped
                                     responsive
                                     :items="osymData"
                                     :fields="selectedExamsFields"
                                     class="mb-0 table-dropdown no-scrollbar border rounded"
                                     ref="osymTable">
                                <template #cell(button)="row">
                                    <div>
                                        <b-button v-if="!row.item.added" @click="addToExamsFromList(row)" variant="primary" size="sm" :title="$t('add')">
                                            <i class="ri-add-line"></i>
                                        </b-button>
                                        <b-button v-if="row.item.added" variant="success" size="sm" :title="$t('added')">
                                            <i class="ri-check-line"></i>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-col>
                        <b-col cols="12" class="mt-2">
                            <b-button @click="osymManuelSwitch = !osymManuelSwitch" variant="primary">{{ $t('add_exam_info_manuel') }}</b-button>
                        </b-col>
                        <b-col v-show="osymManuelSwitch" cols="12">
                            <div class="border rounded mt-2 p-4">
                                <ValidationObserver ref="examForm">
                                    <b-row>
                                        <b-col cols="12" md="4">
                                            <ValidationProvider name="exam" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('exam')">
                                                    <parameter-selectbox
                                                        withoutItems="bau_lang_sufficiency"
                                                        v-model="examForm.exam"
                                                        :public="true"
                                                        code="graduate_application_exams"
                                                        :validate-error="errors[0]"></parameter-selectbox>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="4">
                                            <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('exam_date')">
                                                    <v-date-picker
                                                        v-model="examForm.date"
                                                        locale="tr"
                                                        is-expanded
                                                        :masks="{ input: 'DD-MM-YYYY'}"
                                                        :popover="{ 'visibility': 'click' }">
                                                        <template
                                                            v-slot="{ inputValue, inputEvents }">
                                                            <b-input-group>
                                                                <b-form-input
                                                                    :readonly="true"
                                                                    :value="inputValue"
                                                                    v-on="inputEvents"
                                                                    :state="errors[0] ? false : null"
                                                                ></b-form-input>
                                                                <b-input-group-append>
                                                                    <b-button
                                                                        variant="outline-secondary"
                                                                        class="btn-40"
                                                                        disabled
                                                                    >
                                                                        <i class="ri-calendar-line"></i
                                                                        ></b-button>
                                                                </b-input-group-append>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-input-group>
                                                        </template>
                                                    </v-date-picker>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="4">
                                            <ValidationProvider name="score" rules="required" v-slot="{valid, errors}">
                                                <b-form-group :label="$t('points')">
                                                    <b-form-input
                                                        type="number"
                                                        step="1"
                                                        min="0"
                                                        :state="errors[0] ? false : null"
                                                        v-model="examForm.score"></b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="4" class="justify-content-end mt-2">
                                            <b-button @click="addToExams" type="button" variant="primary">{{ $t('add').toUpper() }}</b-button>
                                        </b-col>
                                    </b-row>
                                </ValidationObserver>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>

import CommonModal from "@/components/elements/CommonModal.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramGroupsSelectbox from "@/components/interactive-fields/ProgramGroupsSelectbox";
import ProgramLevelSelectbox from "@/components/interactive-fields/ProgramLevelSelectbox";
import ThesisWithTrueFalseSelectbox from "@/components/interactive-fields/ThesisWithTrueFalseSelectbox";
import LanguageSelectbox from "@/components/interactive-fields/LanguageSelectbox"
import ProgramDaysSelectbox from "@/components/interactive-fields/ProgramDaysSelectbox";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox"
import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox"
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox"
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"
import ApplicationFormPreview from "./ApplicationFormPreview";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox"

import GraduateApplicationService from "@/services/GraduateApplicationService";
import ProgramService from "@/services/ProgramService";
import qs from "qs";
import moment from "moment";
import ConstantService from "@/services/ConstantService"

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CommonModal,
        GenderSelectbox,
        CountrySelectbox,
        ParameterSelectbox,
        ProgramGroupsSelectbox,
        ProgramLevelSelectbox,
        ThesisWithTrueFalseSelectbox,
        LanguageSelectbox,
        ProgramDaysSelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox,
        UniversitySelectbox,
        YearsSelectbox,
        YesNoSelectbox,
        ApplicationFormPreview,
        CampusSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('graduate_application_form')
        }
    },
    data() {
        return {
            yoksisSearchSwitch: null,
            yoksisData: [],
            yoksisManuelSwitch: false,
            osymSearchSwitch: 0,
            osymData: [],
            osymManuelSwitch: false,
            osymYear: null,
            letterAndSpaceMask: {
                mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                tokens: {
                    'U': {
                        pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                        transform: function (v) {
                            v = v.replaceAll('i', 'İ');
                            return v.toUpper();
                        }
                    }
                }
            },
            floatMask: {
                mask: '#####',
                tokens: {
                    '#': {
                        pattern: /[\-\+]?[0-9]*(\.[0-9]+)*([0-9]+)?/,

                    }
                }
            },
            floatMaskFour: {
                mask: '####',
                tokens: {
                    '#': {
                        pattern: /[\-\+]?[0-9]*(\.[0-9]+)*([0-9]+)?/,

                    }
                }
            },
            tabIndex: 0,
            kvkkStatus: false,
            stateData: {},
            alertVariant: "success",
            timerCount: 5,
            smsSendAgainButtonStatus: false,
            updateSwitch: false,

            smsVerifyActionForm: {
                verifyCode: null
            },

            personalInfoForm: {
                nationalId: null,
                name: null,
                surname: null,
                gender: null,
                birthdate: null,
                nationalityCode: null,
                militaryStatus: null,
                email: null,
                phone: 5,
                applicationType: null,
            },

            jobContactForm: {
                sector: null,
                corporation: null,
                job: null,
                jobDepartment: null,
                title: null,
                experience: null,
                neighborhood: null,
                street: null,
                streetNo: null,
                city: null,
                district: null,
                homeTel: null,
                homeTelCode: null,
                neighborhoodJob: null,
                streetJob: null,
                streetNoJob: null,
                cityJob: null,
                districtJob: null,
                jobTel: null,
                jobTelCode: null,
            },

            educationExamForm: {
                graduationDegree: null,
                university: null,
                faculty: null,
                department: null,
                year: null,
                average: null,
                averageSystem: null,
                hash: null
            },

            examForm: {
                exam: null,
                date: null,
                score: null,
                bauExam: null
            },

            bauExamForm: {
                bauExam: null
            },

            programFilter: {
                department: null,
                level: null,
                withThesis: null,
                language: null,
                campusId: null,
                programDays: null
            },

            availablePrograms: [],
            availableProgramsFields: [
                {
                    key: 'code',
                    label: this.$t('program_code'),
                },
                {
                    key: 'name',
                    label: this.$t('program_name'),
                },
                {
                    key: 'language',
                    label: this.$t('program_language').toUpper(),
                },
                {
                    key: 'with_thesis',
                    label: this.$t('with_without_thesis').toUpper(),
                },
                {
                    key: 'buttons',
                    label: '',
                },
            ],

            selectedPrograms: [],
            selectedProgramsFields: [
                {
                    key: 'code',
                    label: this.$t('program_code'),
                },
                {
                    key: 'name',
                    label: this.$t('program_name'),
                },
                {
                    key: 'buttons',
                    label: '',
                },
            ],

            selectedEducations: [],
            selectedEducationsFields: [
                {
                    key: 'university_name',
                    label: this.$t('university').toUpper(),
                },
                {
                    key: 'graduation_degree',
                    label: this.$t('graduation_degree').toUpper(),
                },
                {
                    key: 'faculty',
                    label: this.$t('faculty'),
                },
                {
                    key: 'department',
                    label: this.$t('department'),
                },
                {
                    key: 'year',
                    label: this.$t('graduation_year').toUpper(),
                },
                {
                    key: 'graduation_system',
                    label: this.$t('system_name').toUpper(),
                },
                {
                    key: 'average',
                    label: this.$t('graduation_average').toUpper(),
                },
                {
                    key: 'button',
                    label: '',
                },
            ],

            selectedExams: [],
            selectedExamsFields: [
                {
                    key: 'name',
                    label: this.$t('exam').toUpper(),
                },
                {
                    key: 'date',
                    label: this.$t('exam_date').toUpper(),
                },
                {
                    key: 'score',
                    label: this.$t('score'),
                },
                {
                    key: 'button',
                    label: '',
                },
            ],

            requiredDocuments: [],
            files: [],
            serviceData: {},

            alertVariantUpdate: "success",
            timerCountUpdate: 5,
            smsSendAgainButtonStatusUpdate: false,
            smsVerifyActionUpdateForm: {
                verifyCode: null
            },
            bauExamShow: false,
            otherDocuments: [],
        }
    },
    created() {
        this.formDateCheck()
        this.$store.dispatch("graduateApplication/initKvkk");
        this.stateData = {
            kvkk: this.$store.getters['graduateApplication/getKvkk'],
            tabIndex: this.$store.getters['graduateApplication/getLbfTab'],
            personalInfo: this.$store.getters['graduateApplication/getLbfPersonalInfo'],
        }
        this.tabIndex = this.stateData.tabIndex

        if (this.stateData.personalInfo) {
            this.personalInfoForm.nationalId = this.stateData.personalInfo.national_id
            this.personalInfoForm.name = this.stateData.personalInfo.name
            this.personalInfoForm.surname = this.stateData.personalInfo.surname
            this.personalInfoForm.gender = this.stateData.personalInfo.gender
            this.personalInfoForm.birthdate = this.stateData.personalInfo.birthdate
            this.personalInfoForm.nationalityCode = this.stateData.personalInfo.nationality_code
            this.personalInfoForm.militaryStatus = this.stateData.personalInfo.military_status
            this.personalInfoForm.email = this.stateData.personalInfo.email
            this.personalInfoForm.phone = this.stateData.personalInfo.phone
            this.personalInfoForm.applicationType = this.stateData.personalInfo.application_type
        }

    },
    mounted() {
        this.$store.dispatch("graduateApplication/initKvkk");
        this.stateData = {
            kvkk: this.$store.getters['graduateApplication/getKvkk'],
            tabIndex: this.$store.getters['graduateApplication/getLbfTab'],
            personalInfo: this.$store.getters['graduateApplication/getLbfPersonalInfo'] != null ? this.$store.getters['graduateApplication/getLbfPersonalInfo'] : false,
        }

        if (this.stateData.personalInfo) {
            this.personalInfoForm.nationalId = this.stateData.personalInfo.national_id
            this.personalInfoForm.name = this.stateData.personalInfo.name
            this.personalInfoForm.surname = this.stateData.personalInfo.surname
            this.personalInfoForm.gender = this.stateData.personalInfo.gender
            this.personalInfoForm.birthdate = this.stateData.personalInfo.birthdate
            this.personalInfoForm.nationalityCode = this.stateData.personalInfo.nationality_code
            this.personalInfoForm.militaryStatus = this.stateData.personalInfo.military_status
            this.personalInfoForm.email = this.stateData.personalInfo.email
            this.personalInfoForm.phone = this.stateData.personalInfo.phone
            this.personalInfoForm.applicationType = this.stateData.personalInfo.application_type
        }

        if (this.stateData.kvkk) {
            this.hideKvkkModal()
        }
        else {
            this.showKvkkModal()
        }

        if (this.stateData.personalInfo.form_status == 'update') {
            this.updateSwitch = true
        }
    },
    watch: {
        'personalInfoForm.gender': function (val) {
            if (val == 'Kadın') {
                this.personalInfoForm.militaryStatus = null
            }
        },
        kvkkStatus: function (val) {
            if (!val) {
                this.showKvkkModal()
            }
        },
        tabIndex: {
            handler: function (val) {
                this.stateData = {
                    kvkk: this.$store.getters['graduateApplication/getKvkk'],
                    tabIndex: this.$store.getters['graduateApplication/getLbfTab'],
                    personalInfo: this.$store.getters['graduateApplication/getLbfPersonalInfo'],
                }
                this.tabIndex = this.stateData.tabIndex

                if (this.stateData.personalInfo) {
                    this.personalInfoForm.nationalId = this.stateData.personalInfo.national_id
                    this.personalInfoForm.name = this.stateData.personalInfo.name
                    this.personalInfoForm.surname = this.stateData.personalInfo.surname
                    this.personalInfoForm.gender = this.stateData.personalInfo.gender
                    this.personalInfoForm.birthdate = this.stateData.personalInfo.birthdate
                    this.personalInfoForm.nationalityCode = this.stateData.personalInfo.nationality_code
                    this.personalInfoForm.militaryStatus = this.stateData.personalInfo.military_status
                    this.personalInfoForm.email = this.stateData.personalInfo.email
                    this.personalInfoForm.phone = this.stateData.personalInfo.phone
                    this.personalInfoForm.applicationType = this.stateData.personalInfo.application_type
                }

                //Program Seçimi
                if (this.tabIndex == 1) {
                    this.getProgramPerferences()
                }
                else if (this.tabIndex == 2) {
                    this.getJobContact()
                }
                else if (this.tabIndex == 3) {
                    this.getExams()
                    this.getEducations()
                }
                else if (this.tabIndex == 4) {
                    this.getRequiredDocuments()
                    this.getOtherDocuments()
                }

            }
        },
        timerEnabled(value) {
            if (value) {
                this.timerCount--
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                else if (value > 0 && !this.timerEnabled) {
                    this.alertShow = false
                    this.smsSendAgainButtonStatus = false
                }
                else {
                    this.alertVariant = "danger"
                    this.alertShow = true
                    this.smsSendAgainButtonStatus = true
                }

            },
            immediate: true,
        },

        timerEnabledUpdate(value) {
            if (value) {
                this.timerCountUpdate--
            }
        },
        timerCountUpdate: {
            handler(value) {

                if (value > 0 && this.timerEnabledUpdate) {
                    setTimeout(() => {
                        this.timerCountUpdate--;
                    }, 1000);
                }
                else if (value > 0 && !this.timerEnabledUpdate) {
                    this.alertShowUpdate = false
                    this.smsSendAgainButtonStatusUpdate = false
                }
                else {
                    this.alertVariantUpdate = "danger"
                    this.alertShowUpdate = true
                    this.smsSendAgainButtonStatusUpdate = true
                }

            },
            immediate: true,
        },

        programFilter: {
            handler(val) {
                const counter = []
                Object.keys(val).some(elem => {
                    if (val[elem] != null) {
                        counter.push(elem)
                    }
                })
                this.availablePrograms = []
                if (counter.length > 0) {
                    this.getAvailablePrograms()
                }
            },
            deep: true
        },
    },
    methods: {
        showKvkkModal() {
            this.$refs.kvkkModal.$refs.commonModal.show()
        },
        hideKvkkModal() {
            this.$refs.kvkkModal.$refs.commonModal.hide()
        },
        closeKvkkModal() {
            this.$router.push({name: 'graduateApplicationLogin'})
        },
        showKvkk1Modal() {
            this.$refs.kvkk1Modal.$refs.commonModal.show()
        },
        showKvkk2Modal() {
            this.$refs.kvkk2Modal.$refs.commonModal.show()
        },
        acceptToContinue() {
            this.$store.dispatch("graduateApplication/kvkkStore").then(() => {
                this.kvkkStatus = true
                this.$refs.kvkkModal.$refs.commonModal.hide()
                this.$refs.graduateInfoModal.$refs.commonModal.show()
            })
        },
        continueInfoModal() {
            this.$refs.graduateInfoModal.$refs.commonModal.hide()
        },

        htmlDecode(input) {
            var doc = new DOMParser().parseFromString(input, "text/html");
            return doc.documentElement.textContent;
        },

        async sendSms() {
            if (!this.updateSwitch) {
                const isValid = await this.$refs.personalInfoForm.validate();

                if (isValid) {
                    let formData = {
                        national_id: this.personalInfoForm.nationalId,
                        name: this.personalInfoForm.name,
                        surname: this.personalInfoForm.surname,
                        birthdate: moment(this.personalInfoForm.birthdate).format('YYYY-MM-DD'),
                        phone: this.personalInfoForm.phone,
                        nationality_code: this.personalInfoForm.nationalityCode,
                        gender: this.personalInfoForm.gender,
                        military_status: this.personalInfoForm.militaryStatus,
                        email: this.personalInfoForm.email,
                        application_type: this.personalInfoForm.applicationType
                    }

                    GraduateApplicationService.sendSms(formData).then(response => {
                        this.$refs.smsVerifyModal.$refs.commonModal.show()
                        this.timerCount = response.data.data.expires_in
                        this.timerEnabled = true
                        this.smsSendAgainButtonStatus = false
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$swal
                                    .fire({
                                        icon: 'error',
                                        html: this.htmlDecode(this.htmlDecode(this.$t('api.' + e.data.message))),
                                        showCancelButton: false,
                                        confirmButtonText: this.$t('ok'),
                                    })
                            }
                        }
                        else {
                            if (e.data.errors.national_id) {
                                this.$refs.personalInfoForm.errors.nationalId.push(e.data.errors.national_id[0]);
                            }
                            if (e.data.errors.name) {
                                this.$refs.personalInfoForm.errors.name.push(e.data.errors.name[0]);
                            }
                            if (e.data.errors.surname) {
                                this.$refs.personalInfoForm.errors.surname.push(e.data.errors.surname[0]);
                            }
                            if (e.data.errors.birthdate) {
                                this.$refs.personalInfoForm.errors.birthdate.push(e.data.errors.birthdate[0]);
                            }
                            if (e.data.errors.phone) {
                                this.$refs.personalInfoForm.errors.phone.push(e.data.errors.phone[0]);
                            }
                            if (e.data.errors.nationality_code) {
                                this.$refs.personalInfoForm.errors.nationalityCode.push(e.data.errors.nationality_code[0]);
                            }
                            if (e.data.errors.gender) {
                                this.$refs.personalInfoForm.errors.gender.push(e.data.errors.gender[0]);
                            }
                            if (e.data.errors.military_status) {
                                this.$refs.personalInfoForm.errors.militaryStatus.push(e.data.errors.military_status[0]);
                            }
                            if (e.data.errors.email) {
                                this.$refs.personalInfoForm.errors.email.push(e.data.errors.email[0]);
                            }
                            if (e.data.errors.application_type) {
                                this.$refs.personalInfoForm.errors.applicationType.push(e.data.errors.application_type[0]);
                            }
                        }
                    })

                }
            }
            else {
                const isValid = await this.$refs.personalInfoForm.validate();

                if (isValid) {
                    if (this.personalInfoForm.phone != this.stateData.personalInfo.phone) {
                        let formData = {
                            national_id: this.stateData.personalInfo.national_id,
                            pin: this.stateData.personalInfo.pin,
                            new_phone: this.personalInfoForm.phone
                        }
                        GraduateApplicationService.updatePhoneSendVerify(formData).then(response => {
                            this.$refs.smsVerifyUpdateModal.$refs.commonModal.show()
                            this.timerCountUpdate = response.data.data.expires_in
                            this.timerEnabledUpdate = true
                            this.smsSendAgainButtonStatusUpdate = false
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + response.data.message))
                        })
                    }
                    else {
                        let formData = {
                            address: this.stateData.personalInfo.address,
                            email: this.personalInfoForm.email,
                            national_id: this.stateData.personalInfo.national_id,
                            pin: this.stateData.personalInfo.pin,
                            sector: this.stateData.personalInfo.sector,
                            nationality_code: this.personalInfoForm.nationalityCode,
                            military_status: this.personalInfoForm.militaryStatus,
                            application_type: this.personalInfoForm.applicationType
                        }

                        GraduateApplicationService.saveAll(formData).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message))
                        }).then(() => {
                            let formData = {
                                national_id: this.stateData.personalInfo.national_id,
                                pin: this.stateData.personalInfo.pin
                            }

                            GraduateApplicationService.show(formData).then(response => {
                                let data = response.data.data
                                let stateData = {
                                    application_type: data.application_type,
                                    birthdate: data.birthdate,
                                    created_at: data.created_at,
                                    email: data.email,
                                    gender: data.gender,
                                    id: data.id,
                                    military_status: data.military_status,
                                    mother_name: data.mother_name,
                                    name: data.name,
                                    national_id: data.national_id,
                                    nationality_code: data.nationality_code,
                                    phone: data.phone,
                                    pin: data.pin,
                                    semester_id: data.semester_id,
                                    status: data.status,
                                    surname: data.surname,
                                    updated_at: data.updated_at,
                                    yoksis_unit_id: data.yoksis_unit_id,
                                    address: data.address,
                                    sector: data.sector,
                                    step_type: data.step_type,
                                    form_status: 'update'
                                }
                                this.$store.dispatch("graduateApplication/restoreLbfPersonalInfo", stateData);
                                this.$store.dispatch("graduateApplication/restoreTab", 1);
                                this.tabIndex = 1
                            })
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + e.data.message))
                        })
                    }
                }

            }
        },

        async approvalSmsVerifyUpdate() {
            const isValid = await this.$refs.smsVerifyActionUpdateForm.validate();
            if (isValid) {
                let smsData = {
                    national_id: this.stateData.personalInfo.national_id,
                    pin: this.stateData.personalInfo.pin,
                    verify_code: this.smsVerifyActionUpdateForm.verifyCode
                }

                GraduateApplicationService.updatePhoneVerify(smsData).then(() => {
                    let formData = {
                        national_id: this.stateData.personalInfo.national_id,
                        pin: this.stateData.personalInfo.pin
                    }
                    GraduateApplicationService.show(formData).then(response => {
                        let data = response.data.data
                        let stateData = {
                            application_type: data.application_type,
                            birthdate: data.birthdate,
                            created_at: data.created_at,
                            email: data.email,
                            gender: data.gender,
                            id: data.id,
                            military_status: data.military_status,
                            mother_name: data.mother_name,
                            name: data.name,
                            national_id: data.national_id,
                            nationality_code: data.nationality_code,
                            phone: data.phone,
                            pin: data.pin,
                            semester_id: data.semester_id,
                            status: data.status,
                            surname: data.surname,
                            updated_at: data.updated_at,
                            yoksis_unit_id: data.yoksis_unit_id,
                            address: data.address,
                            sector: data.sector
                        }
                        this.$store.dispatch("graduateApplication/restoreLbfPersonalInfo", stateData);
                        this.$store.dispatch("graduateApplication/restoreTab", 1);
                        this.$refs.smsVerifyUpdateModal.$refs.commonModal.hide()
                        this.tabIndex = 1
                    })
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.pin) {
                            this.$refs.smsVerifyActionUpdateForm.errors.verifyCode.push(e.data.errors.verify_code[0]);
                        }
                    }
                })
            }
        },

        EnterCodeAgainFuncUpdate() {
            this.alertVariantUpdate = "success"
            this.smsVerifyActionUpdateForm.verifyCode = null
            this.sendSms()
        },

        async approvalSmsVerify() {
            const isValid = await this.$refs.smsVerifyActionForm.validate();
            if (isValid) {
                let smsData = {
                    national_id: this.personalInfoForm.nationalId,
                    pin: this.smsVerifyActionForm.verifyCode
                }

                GraduateApplicationService.verifySms(smsData).then(response => {
                    response.data.data.graduate_application.form_status = 'new'
                    this.$store.dispatch("graduateApplication/restoreLbfPersonalInfo", response.data.data.graduate_application);
                    this.$store.dispatch("graduateApplication/restoreTab", 1);
                    this.tabIndex = 1
                    this.$refs.smsVerifyModal.$refs.commonModal.hide()
                    this.$swal.fire({
                        icon: 'info',
                        html: this.$t('graduate_application_after_verify_info'),
                        showCancelButton: false,
                        confirmButtonText: this.$t('ok'),
                    })
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.pin) {
                            this.$refs.smsVerifyActionForm.errors.verifyCode.push(e.data.errors.pin[0]);
                        }
                    }
                })
            }
        },

        EnterCodeAgainFunc() {
            this.alertVariant = "success"
            this.smsVerifyActionForm.verifyCode = null
            this.sendSms()
        },

        getAvailablePrograms() {
            let filter = {}
            this.programFilter.department != null ? filter.group_id = this.programFilter.department : false
            this.programFilter.level != null ? filter.level = this.programFilter.level : false
            this.programFilter.withThesis != null ? filter.with_thesis = this.programFilter.withThesis : false
            this.programFilter.language != null ? filter.language = this.programFilter.language : false
            this.programFilter.campusId != null ? filter.campus_id = this.programFilter.campusId : false
            this.programFilter.programDays != null ? filter.program_days = this.programFilter.programDays : false

            let config = {
                params: {
                    filter: filter,
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            ProgramService.graduateGetAllPublic(config).then(response => {
                let selecteds = []
                this.selectedPrograms.forEach(itm => {
                    selecteds.push(itm.code)
                })

                response.data.data.forEach((itm, index) => {
                    response.data.data[index].name = this.getLocaleText(itm,'name')
                    response.data.data[index].with_thesis = itm.with_thesis == true ? this.$t('with_thesis') : this.$t('without_thesis')
                })

                this.availablePrograms = response.data.data.filter(c => !selecteds.includes(c.code))
            }).catch(e => {
                this.showErrors(e)
            })
        },

        sendToSelecteds(record) {

            let check = this.selectedPrograms.some(elem => {
                if (elem.code === record.item.code) {
                    return true
                }
                return false
            })
            if (!check) {
                let formData = {
                    graduate_application_id: this.stateData.personalInfo.id,
                    pin: this.stateData.personalInfo.pin,
                    faculty_code: 34,
                    program_code: record.item.code,
                    //Kaldırılacak
                    point_type: 1,
                    order_of_success: 1,
                    rank: 1,
                    class: 1
                }
                GraduateApplicationService.saveProgramPerference(formData).then(response => {
                    this.getProgramPerferences()
                    this.getAvailablePrograms()
                    this.$toast.success(this.$t('api.' + response.data.message))
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                })
            }
            else {
                this.$swal
                    .fire({
                        text: this.$t('program_selected'),
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonText: this.$t('ok'),
                    })
            }

        },

        getProgramPerferences() {
            this.selectedPrograms = []
            let formData = {
                graduate_application_id: this.stateData.personalInfo.id,
                pin: this.stateData.personalInfo.pin
            }
            GraduateApplicationService.getProgramPerferences(formData).then(response => {
                response.data.data.forEach((itm) => {
                    this.selectedPrograms.push({
                        code: itm.program.code,
                        name: this.getLocaleText(itm.program,'name'),
                        perferenceId: itm.id
                    })
                })
            })
        },

        sendToAvailables(record, index) {
            this.$swal({
                text: this.$t('program_delete_info'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    let formData = {
                        params: {
                            graduate_application_id: this.stateData.personalInfo.id,
                            pin: this.stateData.personalInfo.pin
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),

                    }

                    GraduateApplicationService.deleteProgramPerference(record.item.perferenceId, formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.getProgramPerferences()
                        this.getAvailablePrograms()
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        }
                    })
                }
            })


        },

        savePrograms() {
            let formData = {
                graduate_application_id: this.stateData.personalInfo.id,
                pin: this.stateData.personalInfo.pin
            }
            GraduateApplicationService.preferanceSave(formData).then(response => {
                this.$store.dispatch("graduateApplication/restoreTab", 2);
                this.tabIndex = 2
            }).catch(e => {
                if (e.status == '404' || e.status == '406') {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                }
            })

        },

        async saveJobContact() {
            const isValid = await this.$refs.jobContactForm.validate();
            if (isValid) {
                let formData = {
                    national_id: this.stateData.personalInfo.national_id,
                    pin: this.stateData.personalInfo.pin,
                    email: this.stateData.personalInfo.email,
                    birthplace: 'kaldırılacak',
                    sector: this.jobContactForm.sector,
                    institution_working: this.jobContactForm.corporation,
                    job: this.jobContactForm.job,
                    working_unit: this.jobContactForm.jobDepartment,
                    title: this.jobContactForm.title,
                    work_experience: this.jobContactForm.experience,
                    neighbourhood: this.jobContactForm.neighborhood,
                    street: this.jobContactForm.street,
                    address: this.jobContactForm.streetNo,
                    province: this.jobContactForm.city,
                    district: this.jobContactForm.district,
                    home_phone: this.jobContactForm.homeTelCode + this.jobContactForm.homeTel
                }
                this.jobContactForm.neighborhoodJob != null ? formData.work_neighbourhood = this.jobContactForm.neighborhoodJob : false
                this.jobContactForm.streetJob != null ? formData.work_street = this.jobContactForm.streetJob : false
                this.jobContactForm.streetNoJob != null ? formData.work_address = this.jobContactForm.streetNoJob : false
                this.jobContactForm.cityJob != null ? formData.work_province = this.jobContactForm.cityJob : false
                this.jobContactForm.districtJob != null ? formData.work_district = this.jobContactForm.districtJob : false
                this.jobContactForm.jobTel != null ? formData.work_phone = this.jobContactForm.jobTelCode + this.jobContactForm.jobTel : false

                GraduateApplicationService.contactInfoSave(formData).then(response => {
                    this.$store.dispatch("graduateApplication/restoreTab", 3);
                    this.tabIndex = 3
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.sector) {
                            this.$refs.jobContactForm.errors.sector.push(e.data.errors.sector[0]);
                        }
                        if (e.data.errors.institution_working) {
                            this.$refs.jobContactForm.errors.corporation.push(e.data.errors.institution_working[0]);
                        }
                        if (e.data.errors.job) {
                            this.$refs.jobContactForm.errors.job.push(e.data.errors.job[0]);
                        }
                        if (e.data.errors.working_unit) {
                            this.$refs.jobContactForm.errors.jobDepartment.push(e.data.errors.working_unit[0]);
                        }
                        if (e.data.errors.title) {
                            this.$refs.jobContactForm.errors.title.push(e.data.errors.title[0]);
                        }
                        if (e.data.errors.work_experience) {
                            this.$refs.jobContactForm.errors.experience.push(e.data.errors.work_experience[0]);
                        }
                        if (e.data.errors.neighborhood) {
                            this.$refs.jobContactForm.errors.neighborhood.push(e.data.errors.neighborhood[0]);
                        }
                        if (e.data.errors.street) {
                            this.$refs.jobContactForm.errors.street.push(e.data.errors.street[0]);
                        }
                        if (e.data.errors.address) {
                            this.$refs.jobContactForm.errors.streetNo.push(e.data.errors.address[0]);
                        }
                        if (e.data.errors.province) {
                            this.$refs.jobContactForm.errors.province.push(e.data.errors.province[0]);
                        }
                        if (e.data.errors.district) {
                            this.$refs.jobContactForm.errors.district.push(e.data.errors.district[0]);
                        }
                        if (e.data.errors.home_phone) {
                            this.$refs.jobContactForm.errors.homeTel.push(e.data.errors.home_phone[0]);
                        }
                        if (e.data.errors.work_neighborhood) {
                            this.$refs.jobContactForm.errors.neighborhoodJob.push(e.data.errors.work_neighborhood[0]);
                        }
                        if (e.data.errors.work_street) {
                            this.$refs.jobContactForm.errors.streetJob.push(e.data.errors.work_street[0]);
                        }
                        if (e.data.errors.work_address) {
                            this.$refs.jobContactForm.errors.streetNoJob.push(e.data.errors.work_address[0]);
                        }
                        if (e.data.errors.work_province) {
                            this.$refs.jobContactForm.errors.cityJob.push(e.data.errors.work_province[0]);
                        }
                        if (e.data.errors.work_district) {
                            this.$refs.jobContactForm.errors.districtJob.push(e.data.errors.work_district[0]);
                        }
                        if (e.data.errors.work_phone) {
                            this.$refs.jobContactForm.errors.jobTel.push(e.data.errors.work_phone[0]);
                        }
                    }
                })

            }

        },

        getJobContact() {
            let config = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }

            GraduateApplicationService.show(config).then(response => {
                let data = response.data.data
                this.jobContactForm.sector = data.sector
                this.jobContactForm.corporation = data.institution_working
                this.jobContactForm.job = data.job
                this.jobContactForm.jobDepartment = data.working_unit
                this.jobContactForm.title = data.title
                this.jobContactForm.experience = data.work_experience
                this.jobContactForm.neighborhood = data.neighbourhood
                this.jobContactForm.street = data.street
                this.jobContactForm.streetNo = data.address
                this.jobContactForm.city = data.province != null ? parseInt(data.province) : null
                this.jobContactForm.district = data.district
                this.jobContactForm.homeTel = data.home_phone != null ? data.home_phone.slice(3) : null
                this.jobContactForm.homeTelCode = data.home_phone != null ? data.home_phone.slice(0, 3) : null
                this.jobContactForm.neighborhoodJob = data.work_neighbourhood
                this.jobContactForm.streetJob = data.work_street
                this.jobContactForm.streetNoJob = data.work_address
                this.jobContactForm.cityJob = data.work_province != null ? parseInt(data.work_province) : null
                this.jobContactForm.districtJob = data.work_district
                this.jobContactForm.jobTel = data.work_phone != null ? data.work_phone.slice(3) : null
                this.jobContactForm.jobTelCode = data.work_phone != null ? data.work_phone.slice(0, 3) : null

            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })
        },

        async addToEducations() {
            const isValid = await this.$refs.educationExamForm.validate();
            if (isValid) {
                let formData = {
                    graduate_application_id: this.stateData.personalInfo.id,
                    pin: this.stateData.personalInfo.pin,
                    graduation_degree: this.educationExamForm.graduationDegree,
                    university_id: this.educationExamForm.university,
                    faculty: this.educationExamForm.faculty,
                    program: this.educationExamForm.department,
                    graduation_year: this.educationExamForm.year,
                    gpa: this.educationExamForm.average.replace(',','.'),
                    graduation_system: this.educationExamForm.averageSystem,
                    hash: this.yoksisData[0]?.hash
                }

                GraduateApplicationService.saveEducation(formData).then(response => {
                    this.getEducations()
                    this.educationExamForm = {}
                    this.$refs.educationExamForm.reset();
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.yoksisManuelSwitch = false
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })
            }
        },

        getEducations() {
            let config = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }

            this.selectedEducations = []
            GraduateApplicationService.show(config).then(response => {
                response.data.data.educations.forEach((index) => {
                    this.selectedEducations.push({
                        id: index.id,
                        graduationDegree: index.graduation_degree,
                        university: index.university_id,
                        university_name: this.getLocaleText(index.university,'name'),
                        graduation_degree: this.getLocaleText(index,'graduation_degree_name'),
                        graduation_system: this.getLocaleText(index,'graduation_system_name'),
                        faculty: index.faculty,
                        department: index.program,
                        year: index.graduation_year,
                        average: index.gpa,
                        averageSystem: index.graduation_system

                    })
                })
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })
        },

        deleteFromSelectedEducations(record) {
            this.$swal({
                text: this.$t('education_delete_info'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    let formData = {
                        params: {
                            graduate_application_id: this.stateData.personalInfo.id,
                            pin: this.stateData.personalInfo.pin
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),

                    }
                    GraduateApplicationService.deleteEducation(record.item.id, formData).then(response => {
                        this.getEducations()
                        this.$toast.success(this.$t('api.' + response.data.message));
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    })

                }
            })

        },

        async addToExams() {
            const isValid = await this.$refs.examForm.validate();
            if (isValid) {
                let formData = {
                    graduate_application_id: this.stateData.personalInfo.id,
                    pin: this.stateData.personalInfo.pin,
                    graduate_exam_code: this.examForm.exam,
                    date: moment(this.examForm.date).format('YYYY-MM-DD'),
                    score: this.examForm.score
                }

                GraduateApplicationService.saveExam(formData).then(response => {
                    this.getExams()
                    this.examForm = {}
                    this.$refs.examForm.reset();
                    this.$toast.success(this.$t('api.' + response.data.message));
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })

            }
        },

        getExams() {
            let config = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }
            this.selectedExams = []
            GraduateApplicationService.show(config).then(response => {
                this.serviceData = response.data.data
                this.serviceData.preferences.forEach(itm => {
                    if (itm.level == 'YL') {
                        this.bauExamShow = true
                    }
                })
                this.bauExamForm.bauExam = this.serviceData.proficiency_status
                if (this.serviceData.exams != null) {
                    Object.keys(this.serviceData.exams).forEach(index => {
                        this.selectedExams.push({
                            exam: this.serviceData.exams[index].code,
                            name: this.getLocaleText(this.serviceData.exams[index],'name'),
                            date: this.serviceData.exams[index].date,
                            score: this.serviceData.exams[index].score
                        })
                    })
                }
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })
        },

        deleteFromSelectedExams(record) {
            this.$swal({
                text: this.$t('exam_delete_info'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    let formData = {
                        params: {
                            graduate_application_id: this.stateData.personalInfo.id,
                            graduate_exam_code: record.item.exam,
                            pin: this.stateData.personalInfo.pin
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),

                    }

                    GraduateApplicationService.deleteExam(formData).then(response => {
                        this.getExams()
                        this.$toast.success(this.$t('api.' + response.data.message));
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                }
            })

        },

        async saveEducationExam() {
            const isValid = await this.$refs.bauExamForm.validate();
            if (isValid) {
                let formData = {
                    address: this.serviceData.address,
                    email: this.serviceData.email,
                    national_id: this.serviceData.national_id,
                    pin: this.serviceData.pin,
                    sector: this.serviceData.sector,
                    proficiency_status: this.bauExamForm.bauExam
                }
                GraduateApplicationService.saveAll(formData).then(response => {
                    let eduData = {
                        graduate_application_id: this.stateData.personalInfo.id,
                        pin: this.stateData.personalInfo.pin
                    }
                    GraduateApplicationService.educationSave(eduData).then(response => {
                        this.$store.dispatch("graduateApplication/restoreTab", 4);
                        this.tabIndex = 4
                    }).catch(e => {
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message))
                })
            }
        },

        savePreview() {
            if (this.stateData.personalInfo.step_type != 'application_preview') {
                this.$swal({
                    title: this.$t('check_it_title'),
                    text: this.$t('preview_save_btn_info'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                }).then(response => {
                    if (response.isConfirmed == true) {
                        let formData = {
                            graduate_application_id: this.stateData.personalInfo.id,
                            pin: this.stateData.personalInfo.pin
                        }

                        GraduateApplicationService.sendApprove(formData).then(response => {
                            this.$store.dispatch("graduateApplication/logout");
                            this.$toast.success(this.$t('api.' + response.data.message));
                            let pinCode = this.stateData.personalInfo.pin
                            let content = this.$t('application_success_info').replace('$$$$', pinCode)

                            this.$swal
                                .fire({
                                    title: this.$t('application_success'),
                                    text: content,
                                    icon: 'info',
                                    showCancelButton: false,
                                    confirmButtonText: this.$t('application_follow_link').toUpper(),
                                }).then(response => {
                                if (response.isConfirmed == true) {
                                    this.$router.push({name: 'graduateApplicationLogin'})
                                }
                            })
                        }).catch(e => {
                            this.$toast.error(this.$t('api.' + e.data.message))
                        })

                    }
                })
            }
            else {
                this.$store.dispatch("graduateApplication/logout")
                this.$router.push({name: 'graduateApplicationLogin'})
                this.$toast.success(this.$t('application_update_success'))
            }
        },

        goToUpdate(tab) {
            this.$store.dispatch("graduateApplication/restoreTab", tab);
            this.tabIndex = tab
        },

        getRequiredDocuments() {
            let formData = {
                graduate_application_id: this.stateData.personalInfo.id,
                pin: this.stateData.personalInfo.pin
            }
            GraduateApplicationService.requiredDocuments(formData).then(response => {
                this.requiredDocuments = response.data.data
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })
        },

        getOtherDocuments() {
            let config = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }
            GraduateApplicationService.show(config).then(response => {
                if (response.data.data.otherDocuments) {
                    this.otherDocuments = response.data.data.otherDocuments
                }
            }).catch(e => {
                if (e.status == 406) {
                    this.$toast.error(this.$t('api.' + e.data.message));
                }
            })
        },

        saveDocuments() {
            this.$store.dispatch("graduateApplication/restoreTab", 5);
            this.tabIndex = 5
        },

        upload(index) {
            //setTimeout(() => {
            let formData = new FormData()
            formData.append('graduate_application_id', this.stateData.personalInfo.id)
            formData.append('pin', this.stateData.personalInfo.pin)
            formData.append('type', index)
            formData.append('file', this.files[index])

            GraduateApplicationService.uploadDocument(formData).then(response => {
                this.$toast.success(this.$t('api.' + response.data.message))
                this.getRequiredDocuments()
                this.getOtherDocuments()
            }).catch(e => {
                if (e.status == 422) {
                    this.$toast.error(e.data.errors.file[0]);
                }
                if (e.status == 406) {
                    this.$toast.error(this.$t('api.' + e.data.message));
                }
            })
            //},2000)
        },

        deleteFile(index, otherDocumentId) {
            let params = {
                graduate_application_id: this.stateData.personalInfo.id,
                pin: this.stateData.personalInfo.pin,
                type: index
            }
            if(otherDocumentId != 0){
                params.type = 'other_documents'
                params.other_document_id = otherDocumentId
            }

            let formData = {
                params: params,

                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            GraduateApplicationService.deleteFile(formData).then(response => {
                this.$toast.success(this.$t('api.' + response.data.message))
                this.getRequiredDocuments()
                this.getOtherDocuments()
            }).catch(e => {
                if (e.status == 406) {
                    this.$toast.error(this.$t('api.' + e.data.message));
                }
            })
        },

        downloadFile(type, fileName, otherDocumentId) {
            let document = {
                type: type,
                pin: this.stateData.personalInfo.pin
            }
            if(otherDocumentId != 0){
                document.other_document_id = otherDocumentId
                document.type = 'other_documents'
            }

            GraduateApplicationService.downloadDocument(this.stateData.personalInfo.id, document).then(response => {
                this._downloadFile(response, fileName)
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })
        },

        logout() {
            this.$store.dispatch("graduateApplication/logout");
            this.$router.push({name: 'graduateApplicationLogin'})
        },

        searchByYoksis(){
            this.yoksisSearchSwitch = true
            this.yoksisData = []
            this.$refs.yoksisSearchModal.$refs.commonModal.show()
            this.yoksisManuelSwitch = false
            this.educationExamForm = {}
            let formData = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin
            }
            GraduateApplicationService.yokisInfoSearch(formData).then(response => {
                if(typeof response.data.data == 'object'){
                    Object.keys(response.data.data).forEach(index => {
                        if(response.data.data[index].graduation_year) {
                            this.yoksisData.push({
                                hash: response.data.data[index].hash,
                                graduation_degree: response.data.data[index].unit_type,
                                graduation_degree_code: response.data.data[index].graduation_degree_code,
                                university_id: response.data.data[index].university_id,
                                university_name: response.data.data[index].university_name,
                                faculty: response.data.data[index].faculty,
                                department: response.data.data[index].program,
                                year: response.data.data[index].graduation_year,
                                average: response.data.data[index].diploma_point,
                                graduation_system: response.data.data[index].diploma_point_system,
                                graduation_system_code: response.data.data[index].graduation_system_code
                            })
                        }
                    })
                } else {
                    response.data.data.forEach(data => {
                        if(data.graduation_year) {
                            this.yoksisData.push({
                                hash: data.hash,
                                graduation_degree: data.unit_type,
                                graduation_degree_code: data.graduation_degree_code,
                                university_id: data.university_id,
                                university_name: data.university_name,
                                faculty: data.faculty,
                                department: data.program,
                                year: data.graduation_year,
                                average: data.diploma_point,
                                graduation_system: data.diploma_point_system,
                                graduation_system_code: data.graduation_system_code
                            })
                        }

                    })
                }
                this.yoksisSearchSwitch = false
            }).catch(e => {
                this.showErrors(e, null)
            })
        },

        addToEducationsFromList(row){
            let formData = {
                graduate_application_id: this.stateData.personalInfo.id,
                pin: this.stateData.personalInfo.pin,
                graduation_degree: row.item.graduation_degree_code,
                university_id: row.item.university_id,
                faculty: row.item.faculty,
                program: row.item.department,
                graduation_year: row.item.year,
                gpa: row.item.average,
                graduation_system: row.item.graduation_system_code,
                hash: row.item.hash
            }

            GraduateApplicationService.saveEducation(formData).then(response => {
                this.getEducations()
                this.$toast.success(this.$t('api.' + response.data.message));
                this.yoksisData[row.index].added = true
                this.$refs.yoksisTable.refresh();
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message));
            })
        },

        openOsymModal(){
            this.osymSearchSwitch = 1
            this.osymYear = null
            this.$refs.osymSearchModal.$refs.commonModal.show()
        },

        searchByOsym(){
            this.osymSearchSwitch = 2
            this.osymData = []

            this.osymManuelSwitch = false
            this.examForm = {}

            let formData = {
                national_id: this.stateData.personalInfo.national_id,
                pin: this.stateData.personalInfo.pin,
                year: this.osymYear
            }
            GraduateApplicationService.osymInfoSearch(formData).then(response => {
                response.data.data.forEach(data => {
                    this.osymData.push({
                        name: data.exam_type,
                        date: data.exam_date,
                        score: data.score,
                        graduate_exam_code: data.graduate_exam_code
                    })
                })
                this.osymSearchSwitch = 3
            }).catch(e => {
                this.showErrors(e, null)
            })
        },

        addToExamsFromList(row){
            let formData = {
                graduate_application_id: this.stateData.personalInfo.id,
                pin: this.stateData.personalInfo.pin,
                graduate_exam_code: row.item.graduate_exam_code,
                date: row.item.date,
                score: row.item.score.replace(',','.')
            }

            GraduateApplicationService.saveExam(formData).then(response => {
                this.getExams()
                this.examForm = {}
                this.$toast.success(this.$t('api.' + response.data.message));
                this.osymData[row.index].added = true
                this.$refs.osymTable.refresh();
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message));
            })
        },

        formDateCheck(){
            let config = {
                params: {
                    key: 'graduate_application.start_date'
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            ConstantService.getValue(config).then(response => {
                let conf = {
                    params: {
                        key: 'graduate_application.end_date'
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                ConstantService.getValue(conf).then(resp => {
                    let startDate = moment(response.data.data).valueOf()
                    let endDate = moment(resp.data.data).valueOf()
                    let timestamp = moment().valueOf()
                    if(timestamp < startDate || timestamp > endDate){
                        this.$router.push('/graduate/application/form/past/date')
                    }
                }).catch(e => {
                    this.showErrors(e, null)
                })
            }).catch(e => {
                this.showErrors(e, null)
            })
        }

    }
};
</script>
<style>
.rowClass {
    cursor: all-scroll;
}
</style>

